
.room-page-container{

   header{

      display: flex;
      width: 100%;      
      justify-content: space-between;
      align-items: center;            
      border-bottom: 1px solid #ccc;
      padding: 24px 15%;      
      
      >img{
         max-height: 45px;
      }
   }

   main{      
      width: 100%;
      padding: 24px 25%;
   }

   .room-title{      
      display: flex;     
      width: 100%; 
      align-items: center;    
      margin-bottom: 24px;  

      >h1 {
         padding-right: 15px;
      }
      >span {
         border-radius: 999px;         
         background: #E559F9;
         color: #fff;
         font: 500 14px 'Roboto', sans-serif;         
         padding: 6px 15px;
      }
   }

   form {
      textarea{
         width: 100%;
         background-color: #fefefe;
         border: 0;
         min-height: 130px;
         padding: 16px;
         border-radius: 8px;
         box-shadow: 0 2px 12px  rgba(0,0,0,0.04);
         resize: vertical;
      }
      .form-footer{
         display: flex;
         justify-content: space-between;
         align-items: center;
         margin-top: 16px;

         .user-info{

            display: flex;
            align-items: center;            

            >img{
               width: 32px;
               height: 32px;
               border-radius: 50%;
               margin-right: 8px;   
            }
            >span{
               border: 1px;
               font-family: 'Poppins', sans-serif;
               font-size: 14px;
               font-weight: 500;
               color: #29292E;
            }
         }

         span{
            font-size: 14px;            
            color: #737380;
                                    
            button{
               border: 0;
               background: transparent;
               font-size: inherit;
               color: #6F4BD8;
               text-decoration: underline;    
               cursor: pointer;           
               display: inline-flex;
            }
         }
      }
   }
   .question-section{
      margin-top: 32px;
          
      .noquestions{
         margin-top: 64px;
         text-align: center;
         
         >img{
            width: 150px;
            height: 150px;
            margin-bottom: 16px;
         }
         >h2{
            font-size: 18px;
            font-family: "Poppins", sans-serif;
            font-weight: 700;
         }
         >span{
            font-size: 14px;
            font-family: "Roboto", sans-serif;
            color: #737380;
         }
      }
   }


}


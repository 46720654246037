.adminroom-container{

   header{

      display: flex;
      width: 100%;      
      justify-content: space-between;
      align-items: center;            
      border-bottom: 1px solid #ccc;
      padding: 24px 15%;      

      >img{
         max-height: 45px;         
      }
            
      >div{
         display: flex;
         justify-content: space-between;         
         
         button{
            height: 38px;
            margin-left: 8px;
         }
      }
      
   }

   main{      
      width: 100%;
      padding: 24px 25%;
   }

   .room-title{      
      display: flex;     
      width: 100%; 
      align-items: center;    
      margin-bottom: 24px;  

      >h1 {
         padding-right: 15px;
      }
      >span {
         border-radius: 999px;         
         background: #E559F9;
         color: #fff;
         font: 500 14px 'Roboto', sans-serif;         
         padding: 6px 15px;
      }
   }

   .question-section{
      margin-top: 32px;

      .noquestions{
         margin-top: 64px;
         text-align: center;
         
         >img{
            width: 150px;
            height: 150px;
            margin-bottom: 16px;
         }
         >h2{
            font-size: 18px;
            font-family: "Poppins", sans-serif;
            font-weight: 700;
         }
         >span{
            font-size: 14px;
            font-family: "Roboto", sans-serif;
            color: #737380;
         }
      }
   }


}


.question-container{
   margin-bottom: 12px;
   padding: 24px;
   border: 0;
   background-color: #fff;
   border-radius: 8px;
   box-shadow: 0 2px 12px rgb(0, 0, 0, 0.06);

   span{
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      color: #737380;
   }

   >p{
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      line-height: 24px;
   }
   
   footer{
      display: flex;
      align-items: center ;
      justify-content: space-between;
      margin-top: 24px;
      
      .question-author{         
         display: flex;
         align-items: center;         
   
         >img{
            height: 32px;
            width: 32px;
            border-radius: 50%;
            margin-right: 8px;
         }
      }
      .question-cmds{

         display: flex;
         align-items: center;
         gap:16px;

         button{
            border: 0;
            background-color: transparent;
            cursor: pointer;
         }


         .like-button{                     
            transition: filter 0.2s;
            display: flex;
            align-items: center;
      
            &:hover{
               filter: brightness(0.8);
            }

            >span{
               padding: 10px 8px 0;
            }
            
            .liked{
               color:#6F4BD8 ;
   
               path {
                  stroke: #6F4BD8;
               }
            }
            
         }

         .answeredQuestion{
            svg{
               &:hover{
                  path, circle {               
                     stroke: #6F4BD8;
                  }
               }
            }
            .answered{
               path, circle {               
                  stroke: #6F4BD8;
               }
            }
         }

         .hightLightQuestion{
            svg{
               &:hover{
                  path{               
                     stroke: #6F4BD8;
                  }
               }
            }
            .hightLighted{
               path{
                  stroke:#6F4BD8 ;
               }
            }
         }

         .deleteQuestion{

            svg{
               &:hover{
                  path{               
                     stroke: #E73F5D;
                  }
               }
            }
         }

      }
   
      
   }
}

.hightLight{
   border: 1px solid #835AFD;
   background-color: #F4F0FF;
   
}

.answered{
   border: 0;
   background-color: #DBDCDD;
}
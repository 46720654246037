.button-room-code {
   display: flex;
   align-items: center;   
   min-width: 150px;
   border: 1px solid #835AFD;
   border-radius: 8px;
   background-color: #fff ;
   overflow: hidden;
   cursor: pointer;

   img{
      padding: 8px;
      background-color: #835AFD ;       
   }

   span{
      padding: 8px;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
   }
}
.button{
   height: 50px;
   border-radius: 8px;
   font-weight: 500;
   background: #835afd;
   color: #fff;
   padding: 0 32px;

   display: flex;
   justify-content: center;
   align-items: center;

   cursor: pointer;
   border: 0;

   transition: filter 0.8s;

   img{
       margin-right: 8px;
   }

   &.outlined {
      background-color: #fff;
      border: 1px solid #835afd;
      color: #835afd;
   }

   &:hover:not(:disabled) {
       filter:brightness(1.5);        
   }

   &:disabled{
       opacity: 0.6;
       cursor: not-allowed;
   }
}